import React from "react";
import "@master-wizr/mw-trial/dist/index.css";
import NoSSR from 'react-no-ssr';
import {Steps } from "@master-wizr/mw-trial";
export default function TrialSteps(){
    return(
       <div>
           <NoSSR>
           <Steps location="/trial-steps" />
           </NoSSR>
       </div>
    );
}